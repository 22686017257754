@import "variables";
@import "common";

/* ----------------- VARIABLES ----------------- */
$button-border-radius-default: 10px;
$button-font-family-default: $font-AlegreyaSans;

/* ----------------- MIXINS ----------------- */

/// Base NooS button mixin
@mixin ns-button(
  $font-size,
  $background-color,
  $font-color,
  $border-color,
  $background-color-hover: $background-color,
  $font-color-hover: $font-color,
  $border-color-hover: $border-color,
  $background-color-focus: $background-color,
  $font-color-focus:$font-color,
  $border-color-focus: $border-color,
  $background-color-active: $background-color-focus,
  $font-color-active: $font-color-focus,
  $border-color-active: $border-color-focus,
  $background-color-disabled: $background-color,
  $font-color-disabled: $font-color,
  $border-color-disabled: $border-color
) {
  font-family: $button-font-family-default;
  color: $font-color;
  background-color: $background-color;
  border: 1px solid $border-color;
  border-radius: $button-border-radius-default;
  font-size: $font-size;
  text-align: center;

  &:hover {
    background-color: $background-color-hover;
    border-color: $border-color-hover;
    color: $font-color-hover;
  }

  &:focus {
    outline: none;
    background-color: $background-color-focus;
    border-color: $border-color-focus;
    color: $font-color-focus;
  }

  &:active {
    background-color: $background-color-active;
    border-color: $border-color-active;
    color: $font-color-active;
  }

  &.active {
    background-color: $background-color-active;
    border-color: $border-color-active;
    color: $font-color-active;
  }

  &.disabled {
    background-color: $background-color-disabled;
    border-color: $border-color-disabled;
    color: $font-color-disabled;
    pointer-events: none;
    cursor: default;
  }

  flex-wrap: nowrap;
  white-space: nowrap;
  min-width: max-content;
}

@mixin common-large-pill-btn() {
  height: 47px;
  padding: 5px 15px;
  min-width: 100px;
  font-weight: 700;
  line-height: 24px;
  border-radius: 33px;
  white-space: nowrap;
}

@mixin common-medium-pill-btn() {
  height: 34px;
  padding: 1px 15px;
  min-width: 100px;
  font-weight: 700;
  line-height: 24px;
  border-radius: 33px;
  white-space: nowrap;
}

@mixin common-small-pill-btn() {
  height: 26px;
  padding: 1px 15px;
  min-width: 100px;
  font-weight: 700;
  line-height: 22px;
  border-radius: 33px;
  white-space: nowrap;
}

/* ----------------- CLASSES ----------------- */
// @formatter:off
.ns-button-yellow-filled-very-big {
  @include ns-button(
    26px,
     // default
    $yellow,
    $very-dark-grey,
    $yellow,
     // hover
    $dark-yellow,
    $very-dark-grey,
    $dark-yellow,
     // focus
    $dark-yellow,
    $very-dark-grey,
    $dark-yellow,
     // active
    $dark-yellow,
    $very-dark-grey,
    $dark-yellow,
     // disabled
    $disabled-background,
    $disabled-text-color,
    $disabled-background
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  padding: 10px 25px;
  border-radius: 12px;
}

.ns-button-yellow-filled-big {
  @include ns-button(
    24px,
     // default
    $yellow,
    $very-dark-grey,
    $yellow,
     // hover
    $dark-yellow,
    $very-dark-grey,
    $dark-yellow,
     // focus
    $dark-yellow,
    $very-dark-grey,
    $dark-yellow,
     // active
    $dark-yellow,
    $very-dark-grey,
    $dark-yellow,
     // disabled
    $disabled-background,
    $disabled-text-color,
    $disabled-background
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 10px 25px;
  border-radius: 10px;
}

.ns-button-yellow-filled-normal {
  @include ns-button(
    16px,
     // default
    $yellow,
    $very-dark-blue,
    $yellow,
     // hover
    $dark-yellow,
    $very-dark-blue,
    $dark-yellow,
     // focus
    $dark-yellow,
    $very-dark-blue,
    $dark-yellow,
     // active
    $dark-yellow,
    $very-dark-blue,
    $dark-yellow,
     // disabled
    $disabled-background,
    $disabled-text-color,
    $disabled-background
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
}

.ns-button-yellow-filled-small {
  @include ns-button(
    12px,
     // default
    $yellow,
    $very-dark-grey,
    $yellow,
     // hover
    $dark-yellow,
    $very-dark-grey,
    $dark-yellow,
     // focus
    $dark-yellow,
    $very-dark-grey,
    $dark-yellow,
     // active
    $dark-yellow,
    $very-dark-grey,
    $dark-yellow,
     // disabled
    $disabled-background,
    $disabled-text-color,
    $disabled-background
  );

  font-family: $font-AlegreyaSans;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 5px 14px;
  border-radius: 5px;
}


.ns-button-yellow-line-normal {
  @include ns-button(
    16px,
     // default
    transparent,
    $dark-yellow,
    $dark-yellow,
     // hover
    $light-yellow,
    $dark-yellow,
    $dark-yellow,
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  padding: 10px 20px;
  border-radius: 10px;
}

.ns-button-dark-blue-solid-medium {
  @include ns-button(
    16px,
     // default
    $dark-blue,
    $white,
    $dark-blue,
     // hover
    $very-dark-blue,
    $white,
    $very-dark-blue
  );

  font-family: $font-AlegreyaSans;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  height: 39px;
  padding: 10px 45px;
  border-radius: 5px;
}

.ns-button-dark-blue-solid-small {
  @include ns-button(
    14px,
     // default
    $dark-blue,
    $white,
    $dark-blue,
     // hover
    $very-dark-blue,
    $white,
    $very-dark-blue
  );

  font-family: $font-Roboto;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 9px 15px;
  border-radius: 5px;
}

.ns-button-dark-blue-ghost-normal {
  @include ns-button(
    16px,
     // default
    transparent,
    $dark-blue,
    transparent,
     // hover
    transparent,
    $dark-blue,
    transparent
  );

  font-family: $font-AlegreyaSans;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  height: 39px;
  padding: 10px 45px;
  border-radius: 5px;

  & span {
    margin: auto;
    text-decoration: underline;
  }

  &:hover {
    font-weight: 700
  }
}

.ns-button-dark-blue-ghost-small {
  @include ns-button(
    12px,
     // default
    transparent,
    $dark-blue,
    transparent,
     // hover
    transparent,
    $dark-blue,
    transparent
  );

  font-family: $font-Roboto;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 5px 14px;
  border-radius: 5px;

  & span {
    text-decoration: underline;
  }

  &:hover {
    font-weight: 700
  }
}

.ns-button-green-solid-small {
  @include ns-button(
    14px,
     // default
    $green,
    $white,
    $green,
     // hover
    $green,
    $white,
    $green
  );

  font-family: $font-Roboto;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 9px 15px;
  border-radius: 5px;
}

.ns-button-alt-green-filled-small {
  @include ns-button(
    14px,
     // default
    $alt-green,
    $white,
    $alt-green,
     // hover
    $dark-green,
    $white,
    $dark-green
  );

  font-family: $font-Roboto;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 9px 15px;
  border-radius: 5px;
}

.ns-button-light-filled-big {
  @include ns-button(
    24px,
     // default
    $green,
    $white,
    $green,
     // hover
    $alt-green,
    $white,
    $alt-green
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 10px 25px;
  border-radius: 10px;
}

.ns-button-green-filled-big {
  @include ns-button(
    24px,
     // default
    $green,
    $white,
    $green,
     // hover
    $alt-green,
    $white,
    $alt-green
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 10px 25px;
  border-radius: 10px;
}

.ns-button-green-filled-normal {
  @include ns-button(
    16px,
     // default
    $green,
    $white,
    $green,
     // hover
    $alt-green,
    $white,
    $alt-green
  );

  font-family: $font-AlegreyaSans;
  font-weight: 700;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px 20px;
  border-radius: 10px;
}

.ns-button-green-filled-small {
  @include ns-button(
    12px,
     // default
    $green,
    $white,
    $green,
     // hover
    $alt-green,
    $white,
    $alt-green
  );

  font-family: $font-AlegreyaSans;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 5px 14px;
  border-radius: 5px;
}

.ns-button-green-ghost-big {
  @include ns-button(
      24px,
      // default
      transparent,
      $green,
      transparent,
      // hover
      transparent,
      $dark-green,
      transparent
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 10px 25px;
  border-radius: 10px;

  & span {
    text-decoration: underline;
  }
}

.ns-button-green-ghost-normal {
  @include ns-button(
      16px,
      // default
      transparent,
      $green,
      transparent,
      // hover
      transparent,
    $dark-green,
    transparent
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px 20px;
  border-radius: 10px;

  & span {
    text-decoration: underline;
  }
}

.ns-button-green-ghost-small {
  @include ns-button(
    12px,
     // default
    transparent,
    $green,
    transparent,
     // hover
    transparent,
    $dark-green,
    transparent
  );

  font-family: $font-Roboto;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 5px 14px;
  border-radius: 5px;

  & span {
    text-decoration: underline;
  }

}

.ns-button-light-green-filled-big {
  @include ns-button(
    24px,
     // default
    $very-light-green,
    $very-dark-blue,
    $very-light-green,
     // hover
    $light-green,
    $very-dark-green,
    $light-green
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 10px 25px;
  border-radius: 10px;
}

.ns-button-light-green-filled-normal {
  @include ns-button(
    16px,
     // default
    $very-light-green,
    $very-dark-blue,
    $very-light-green,
     // hover
    $light-green,
    $very-dark-green,
    $light-green
  );

  font-family: $font-AlegreyaSans;
  font-weight: 700;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px 20px;
  border-radius: 10px;
}

.ns-button-light-green-filled-small {
  @include ns-button(
    12px,
     // default
    $very-light-green,
    $very-dark-blue,
    $very-light-green,
     // hover
    $light-green,
    $very-dark-green,
    $light-green
  );

  font-family: $font-AlegreyaSans;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 5px 14px;
  border-radius: 5px;
}

.ns-button-light-green-line-big {
  @include ns-button(
    24px,
     // default
    transparent,
    $light-green,
    $light-green,
     // hover
    $very-light-green,
    $light-green,
    $light-green
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 10px 25px;
  border-radius: 10px;
}

.ns-button-light-green-ghost-small {
  @include ns-button(
    12px,
     // default
    transparent,
    $light-green,
    transparent,
     // hover
    transparent,
    $light-green,
    transparent
  );

  font-family: $font-Roboto;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 5px 14px;
  border-radius: 5px;

  & span {
    text-decoration: underline;
  }

  &:hover {
    font-weight: 700
  }
}

.ns-button-green-line-big {
  @include ns-button(
    24px,
     // default
    transparent,
    $green,
    $green,
     // hover
    $very-light-green,
    $green,
    $green
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 10px 25px;
  border-radius: 10px;
}

.ns-button-green-line-normal {
  @include ns-button(
      16px,
      // default
      transparent,
      $green,
      $green,
      // hover
      #EEFFFB,
      $green,
      $green
  );

  font-family: $font-AlegreyaSans;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  padding: 10px 45px;
  border-radius: 10px;
}

.ns-button-dark-blue-stroke-normal {
  @include ns-button(
      16px,
      // default
      transparent,
      $dark-blue,
      $dark-blue,
      // hover
      #EEFFFB,
      $dark-blue,
      $dark-blue // active
      $dark-blue,
      $dark-blue,
      $white
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px 20px;
  border-radius: 8px;
}

.ns-button-dark-blue-stroke-tiny {
  @include ns-button(
    12px,
     // default
    transparent,
    $dark-blue,
    $dark-blue,
     // hover
    #EEFFFB,
    $dark-blue,
    $dark-blue
  );

  font-family: $font-Roboto;
  font-weight: 700;
  line-height: 15px;
  display: flex;
  align-items: center;
  height: 26px;
  padding: 5px 15px;
  border-radius: 5px;
}

.ns-button-very-dark-blue-line-normal {
  @include ns-button(
      16px,
      // default
      transparent,
      $very-dark-blue,
      $very-dark-blue,
      // hover
      #F3FFFC,
      $very-dark-blue,
      $very-dark-blue
  );

  font-family: $font-AlegreyaSans;
  font-weight: 700;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px 20px;
  border-radius: 10px;
}

.ns-button-red-filled-big {
  @include ns-button(
    24px,
     // default
    $warn-color,
    $white,
    $warn-color,
     // hover
    $dark-warn-color,
    $white,
    $dark-warn-color
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 10px 25px;
  border-radius: 10px;
}

.ns-button-red-filled-normal {
  @include ns-button(
    16px,
     // default
    $warn-color,
    $white,
    $warn-color,
     // hover
    $dark-warn-color,
    $white,
    $dark-warn-color
  );

  font-family: $font-AlegreyaSans;
  font-weight: 700;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px 20px;
  border-radius: 10px;
}

.ns-button-red-line-normal {
  @include ns-button(
      16px,
      // default
      transparent,
      $warn-color,
      $warn-color,
      // hover
      #F5B4B4,
      $warn-color,
      $warn-color
  );

  font-family: $font-AlegreyaSans;
  font-weight: 700;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px 20px;
  border-radius: 10px;
}

.ns-button-red-ghost-normal {
  @include ns-button(
    16px,
     // default
    transparent,
    $warn-color,
    transparent,
     // hover
    transparent,
    $warn-color,
    transparent
  );

  font-family: $font-AlegreyaSans;
  font-weight: 700;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px 20px;
  border-radius: 10px;
}

.ns-button-pill-light-green-solid-small {
  @include ns-button(
    14px,
     // default
    $light-green,
    $dark-blue,
    $light-green,
     // hover
    $green,
    $dark-blue,
    $green
  );
  @include common-small-pill-btn();
}

.ns-button-pill-light-green-solid-large {
  @include ns-button(
    16px,
     // default
    $light-green,
    $dark-blue,
    $light-green,
     // hover
    $green,
    $dark-blue,
    $green
  );
  @include common-large-pill-btn();
}

.ns-button-dark-grey-line-big {
  @include ns-button(
    24px,
     // default
    transparent,
    $dark-grey,
    $dark-grey,
     // hover
    $light-grey,
    $dark-grey,
    $dark-grey
  );

  font-family: $font-AlegreyaSans;
  font-weight: 800;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 10px 25px;
  border-radius: 10px;
}

.ns-button-dark-grey-line-normal {
  @include ns-button(
    16px,
     // default
    transparent,
    $dark-grey,
    $dark-grey,
     // hover
    $light-grey,
    $dark-grey,
    $dark-grey
  );

  font-family: $font-AlegreyaSans;
  font-weight: 700;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px 20px;
  border-radius: 10px;
}

.ns-button-pill-dark-grey-transparent-small {
  @include ns-button(
    14px,
     // default
    transparent,
    $dark-grey,
    transparent,
     // hover
    $light-gris,
    $dark-grey,
    $light-gris
  );
  @include common-small-pill-btn();
}

.ns-button-pill-dark-grey-transparent-medium {
  @include ns-button(
    16px,
     // default
    transparent,
    $dark-grey,
    transparent,
     // hover
    $light-gris,
    $dark-grey,
    $light-gris
  );
  @include common-medium-pill-btn();
}

.ns-button-pill-dark-grey-transparent-large {
  @include ns-button(
    16px,
     // default
    transparent,
    $dark-grey,
    transparent,
     // hover
    $light-gris,
    $dark-grey,
    $light-gris
  );
  @include common-large-pill-btn();
}

.ns-button-icon-no-color-transparent-medium {
  @include ns-button(
      16px,
      // default
      transparent,
      $very-dark-grey,
      transparent,
      // hover
      transparent,
      $very-dark-grey,
      transparent
  );

  display: flex;
  width: 39px;
  height: 39px;
  padding: 10px;
  border-radius: 5px;
}

.donation-amount-btn {
  @include ns-button(
    36px,
     // default
    transparent,
    $dark-blue,
    $dark-blue,
     // hover
    $alt-light-grey,
    $dark-blue,
    $dark-blue,
     // focus & active
    $dark-blue,
    $white,
    $dark-blue);

  font-weight: 500;
  line-height: 43px;
  width: 105px;
  height: 54px;
  padding: 1px 6px;
}

// @formatter:on

.sso-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 255px;
  height: 40px;
  padding: 8px;
  cursor: pointer;
  border: 1px solid #dadce0;
  border-radius: 4px;
  background: $white;

  &:hover {
    border: 1px solid #d2e3fc;
    background-color: rgb(66 133 244 / 4%);
  }

  span {
    font-family: $font-OpenSans;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    width: 100%;
    margin-right: 4px;
    margin-left: 28px;
    text-align: center;
    color: $google-grey-font;
  }

  img {
    position: absolute;
    left: 12px;
    align-self: center;
    width: 18px;
    height: 18px;
  }

  &.disabled {
    cursor: progress;
    color: $light-grey;
    border: 1px solid rgba($light-grey, 50%);

    img {
      opacity: 0.5;
    }
  }
}

// LAC
.completed-light-green {
  background-color: $very-light-green !important;
  border: 1px solid $very-light-green !important;
  color: $very-dark-blue !important;
  cursor: pointer;

  &:hover {
    background-color: $very-light-green !important;
  }
}
