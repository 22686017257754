@import "variables";

/* ----------------- SPACING ----------------- */

$spaceamounts: (0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 28, 30, 32, 34, 36, 38, 40, 42, 43, 44, 45,46, 48, 50, 52, 53, 54, 55, 56, 58, 60, 62, 64, 65, 70, 72, 78, 80, 82, 84, 92, 113, 126); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .ns-m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .ns-m#{str-slice($side, 0, 1)}-minus-#{$space} {
      margin-#{$side}: -#{$space}px !important;
    }

    .ns-p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }

    .ns-#{$side}-#{$space} {
      #{$side}: #{$space}px !important;
    }

    .ns-radius-#{$space} {
      border-radius: #{$space}px !important;
    }

    .ns-m#{str-slice($side, 0, 1)}-auto {
      margin-#{$side}: auto !important;
    }

  }

  .ns-m-#{$space} {
    margin: #{$space}px !important;
  }

  .ns-p-#{$space} {
    padding: #{$space}px !important;
  }
}

/* ----------------- UTILS ----------------- */
// Adjust this to include the height and width amounts you need.
$sizes: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 82, 128, 140, 172);

@each $height in $sizes {
  @each $width in $sizes {
    .icon-#{$width}x#{$height} {
      width: #{$width}px;
      height: #{$height}px;
    }
    .ns-p-#{$width}x#{$height} {
      padding: #{$height}px #{$width}px !important;
    }
  }

  .ns-height-#{$height} {
    height: #{$height}px !important;
  }
  .ns-width-#{$height} {
    width: #{$height}px !important;
  }
}

.ns-height-auto {
  height: auto !important;
}

.no-hover-green {
  &:hover {
    color: $green;
    text-decoration: none;
  }
}

/* ----------------- TEXTS ----------------- */

$fontsizes: (6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40);

@each $size in $fontsizes {
  .ns-font-#{$size} {
    font-size: #{$size}px !important;
  }
  .ns-line-height-#{$size} {
    line-height: #{$size}px !important;
  }
}

$font-weights: (100, 200, 300, 400, 500, 600, 700, 800, 900);

@each $weight in $font-weights {
  .ns-font-w-#{$weight} {
    font-weight: #{$weight} !important;
  }
}

.font-roboto {
  font-family: $font-Roboto !important;
}

.font-alegreya-sans {
  font-family: $font-AlegreyaSans !important;
}

.ns-text-center {
  text-align: center;
}

.ns-text-left {
  text-align: left;
}

.ns-text-right {
  text-align: right;
}

/* ----------------- CLASSES ----------------- */

div.divider-with-text {
  text-align: center;
  border-bottom: 1px solid $light-grey;
  line-height: 0.1em;
  margin: 0 auto;

  span {
    color: $dark-grey;
    padding: 0 34px;
    font-weight: 500;
    font-size: 12px;
  }
}

.invisible {
  opacity: 0 !important;
}

.dont-display {
  display: none !important;
}

.no-pointer-events {
  pointer-events: none;
}

.ns-b-radius-5 {
  border-radius: 5px !important;
}

.ns-b-radius-10 {
  border-radius: 10px !important;
}

.ns-b-radius-15 {
  border-radius: 15px !important;
}

.scrollable-y {
  overflow-y: scroll;
}

.pe-none {
  pointer-events: none !important;
}

.ns-cursor-pointer {
  cursor: pointer;
}

.ns-cursor-default {
  cursor: default !important;
}

.ns-dark-blue-link {
  color: $dark-blue;

  &:hover {
    color: $green;
  }

  &:active {
    color: $dark-blue;
  }
}

div.no-scrollbar {
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox 64+ */
}

div.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

// NooS custom scrollbars
@mixin ns-main-scrollbar($thumb-color, $track-color) {
  // For chrome and chromium browsers (Edge, Safari)
  ::-webkit-scrollbar {
    width: 12px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    width: 8px;
    height: 40%;
    border-radius: 5px;
    background-color: rgba($thumb-color, 0.7);
    border-right: 4px $track-color solid;
    border-left: 4px $track-color solid;
  }

  ::-webkit-scrollbar-button,
  ::-webkit-scrollbar-track-piece,
  ::-webkit-scrollbar-corner,
  ::-webkit-resizer {
    display: none;
  }

  ::-webkit-scrollbar-track {
    background: transparent repeat-y;
    background-size: contain;
  }

  // For Firefox
  * {
    scrollbar-color: $thumb-color transparent;
    scrollbar-width: thin;
  }
}

@mixin ns-scrollbar($thumb-color, $track-color) {
  &::-webkit-scrollbar {
    width: 12px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 40%;
    border-radius: 5px;
    background-color: rgba($thumb-color, 0.7);
    border-right: 4px $track-color solid;
    border-left: 4px $track-color solid;
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-track-piece,
  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent repeat-y;
    background-size: contain;
  }

  scrollbar-color: $thumb-color transparent;
  scrollbar-width: thin;
}

@mixin ns-branded-scrollbar($track-color) {
  &::-webkit-scrollbar {
    width: 12px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 40%;
    border-radius: 5px;
    background: var(--rgba-color);
    opacity: 0.7;
    border-right: 4px $track-color solid;
    border-left: 4px $track-color solid;
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-track-piece,
  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent repeat-y;
    background-size: contain;
  }

  scrollbar-color: var(--color) transparent;
  scrollbar-width: thin;
}
