
// FONT FAMILIES
$font-AlegreyaSans: 'Alegreya Sans', sans-serif;
$font-Alegreya: 'Alegreya', serif;
$font-Roboto: 'Roboto', sans-serif;
$font-OpenSans: 'Open Sans', sans-serif;

// COLORS
$very-dark-blue: #0f4654;
$dark-blue: #2d6675;
$light-blue: #b4d1d9;
$very-light-blue: #f0f6f7;
$very-dark-grey: #2c3240;
$dark-grey: #5a6170;
$light-grey: #d1d5de;
$very-dark-green: #09323d;
$dark-green: #099679;
$green: #34d1b0;
$light-green: #abebdd;
$very-light-green: #e8fffa;
$dark-yellow: #e3a903;
$yellow: #fdbc03;
$light-yellow: #fdefc8;
$very-light-yellow: #fff8e4;
$white: #fff;
$black: #000;
$gris: #c9c9c7;
$light-gris: #f3f3f3;
$alt-light-grey: #f4f5f7;
$alt-green: #02c39b;

// OTHERS
$warn-color: #eb5757;
$dark-warn-color: #cf2525;
$bright-yellow: #f6e230;
$google-grey-font: #3c4043;

// BUTTONS
$disabled-background: #e8e8e8;
$disabled-text-color: #acacac;

// EXTRA GREYS
$dark-grey-alt: #888;
$grey-1: #111;
$grey-2: #222;
$grey-3: #333;
$grey-4: #444;
$grey-5: #555;
$grey-6: #666;
$grey-7: #777;
$grey-8: #888;
$grey-9: #999;
$extra-grey-1: #e8e8e8;

@mixin font($ffamily, $fsize, $lh, $ls, $fweight, $fstyle, $color) {
  font-family: $ffamily;
  font-size: $fsize;
  line-height: $lh;
  letter-spacing: $ls;
  font-weight: $fweight;
  font-style: $fstyle;
  color: $color;
}

@mixin button() {
  border: none;
  display: block;
  font-weight: bold;
  color: $very-dark-grey;
  outline: none;

  &.serif {
    font-family: $font-Alegreya;
  }

  &.sans-serif {
    font-family: $font-AlegreyaSans;
  }

  &.with-bird {
    span {
      display: inline-block;
      position: relative;
      vertical-align: middle;
    }

    span.bird {
      margin-right: 10px;

      &:before {
        content: "";
        position: absolute;
        top: -3px;
        left: -6px;
        width: 39px;
        height: 39px;
        border-radius: 50%;
        background-color: rgb(255 255 255 / 50%);
        z-index: 1;
      }

      img {
        width: 42px;
        position: relative;
        z-index: 2;
      }
    }

    span.bird-one-time {
      margin-right: 25px;

      &:before {
        content: "";
        position: absolute;
        top: -3px;
        left: 10px;
        width: 39px;
        height: 39px;
        border-radius: 50%;
        background-color: rgb(255 255 255 / 50%);
        z-index: 1;
      }

      img {
        width: 42px;
        position: relative;
        z-index: 2;
      }
    }
  }

  &.green {
    background-color: $green;

    &:hover {
      background-color: $dark-green;
    }

    &:hover:enabled {
      background-color: $dark-green;
    }
  }

  &.yellow {
    background-color: $yellow;

    &:hover {
      background-color: $dark-yellow;
    }

    &:hover:enabled {
      background-color: $dark-yellow;
    }
  }
}

@mixin pin-share {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  opacity: 0.7;
  position: relative;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

  &.share {
    background: no-repeat 8px center/18px 20px url("/assets/icons/share-dark-blue.svg"), $light-green;
  }

  &:hover {
    background-color: $green;
    opacity: 1;
  }
}

// Branding color variables
$colors: (
  "warn": $warn-color,
  "very-dark-blue":$very-dark-blue, "dark-blue":$dark-blue, "light-blue": $light-blue,
  "very-light-blue": $very-light-blue,
  "very-dark-grey":$very-dark-grey, "dark-grey": $dark-grey, "light-grey":$light-grey,
  "very-dark-green": $very-dark-green, "dark-green":$dark-green, "green":$green, "light-green": $light-green, "very-light-green": $very-light-green,
  "dark-yellow": $dark-yellow, "yellow": $yellow, "light-yellow": $light-yellow,
  "black":$black,"white":$white, "gris": $gris, "light-gris": $light-gris, "alt-light-grey": $alt-light-grey, "alt-green": $alt-green,
  "grey-1": $grey-1, "grey-2": $grey-2, "grey-3": $grey-3, "grey-4": $grey-4, "grey-5": $grey-5, "grey-6": $grey-6, "grey-7": $grey-7, "grey-8": $grey-8);

@each $color-name, $color-value in $colors {
  .bg-#{$color-name} {
    background: $color-value !important;
  }
  .ns-#{$color-name} {
    color: $color-value !important;
  }
}

@each $color-name, $color-value in $colors {
  .font-#{$color-name} {
    color: $color-value;
  }
}
